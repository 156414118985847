//
// Thanks @kremalicious
// https://github.com/kremalicious/portfolio/blob/master/src/pages/404.jsx
//
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Content from '../components/Content'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import styles from './404.module.scss'

export default class NotFoundPage extends Component {
  static propTypes = {
    location: PropTypes.object
  }

  handleClick = (e) => {
    e.preventDefault()
  }

  render() {
    return (
      <>
        <Seo location={this.props.location} title="404 - Not Found" />
        <Layout location={this.props.location}>
          <Content>
            <article className={styles.content}>
              <h1>Page not found.</h1>
              <p>
                You just hit a route that doesn&#39;t exist. Check your url,{' '}
                <Link to="/">go back to the homepage</Link>
              </p>
            </article>
          </Content>
        </Layout>
      </>
    )
  }
}
